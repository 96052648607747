.jumbotron {
  background-image: url(/img/hero.jpg);
  background-position: center;
  min-height: 560px;
  text-align: center;
  text-shadow: 2px 2px 4px #000000; }

.footer {
  background-color: #021c3d;
  text-align: center; }

.fa, .fab, .fas {
  color: #fff; }
